import { ResultRefInput, Segment, TimeSeriesDataPoint, TimeSeriesResult } from "@/apis/nannyml";
import { PlotType } from "@/constants/enums";
import { ThresholdValue } from "@/domains/threshold";
import { DateLike } from "@/lib/dateUtils";

/** Get a result ref from a result-like object
 * @param result A result-like object
 *
 * Apollo graphql errors out if there are extra fields in the input object. This function removes all extra fields from
 * the input object to avoid this error.
 */
export const getResultRef = (
  result: Pick<
    TimeSeriesResult,
    "modelId" | "analysisType" | "calculatorType" | "metricName" | "columnName" | "componentName"
  > & { segment: null | Pick<Segment, "id"> }
): ResultRefInput => ({
  modelId: result.modelId,
  analysisType: result.analysisType,
  calculatorType: result.calculatorType,
  metricName: result.metricName,
  columnName: result.columnName,
  componentName: result.componentName,
  segmentId: result.segment?.id,
});

/**
 * Get date step from the given result data
 *
 * Assumes the data uses fixed date increments.
 *
 * @param data Data points to calculate the date step from
 */
export const getDateStep = (data: { startTimestamp: DateLike; endTimestamp: DateLike }): number => {
  return new Date(data.endTimestamp).getTime() - new Date(data.startTimestamp).getTime() + 1;
};

/**
 * Get date range from the given data for plotting purposes
 *
 * Assumes the data is sorted by timestamp.
 *
 * @param data Data points to be plotted
 * @param type Plot type to be used
 * @returns The date range for the given data as a tuple of start and end timestamp
 */
export const getDateRange = (
  data: { startTimestamp: DateLike; endTimestamp: DateLike }[],
  plotType: PlotType
): [number, number] => {
  /* Return a dummy value if there's no data */
  if (data.length === 0) {
    return [Number.MAX_VALUE, -Number.MAX_VALUE];
  }

  return [getStartTimestamp(data[0], plotType), getEndTimestamp(data.at(-1)!, plotType)];
};

/**
 * Get value range from the given result
 * @param result Result to get the value range for
 * @returns Range of values for the given result as a tuple of min and max
 */
export const getValueRange = (result: { data: TimeSeriesDataPoint[]; threshold: ThresholdValue }): [number, number] => {
  return result.data.reduce(
    ([min, max], dp) =>
      [
        Math.min(min, dp.lowerConfidenceBound ?? dp.value ?? Number.MAX_VALUE),
        Math.max(max, dp.upperConfidenceBound ?? dp.value ?? -Number.MAX_VALUE),
      ] as [number, number],
    [result.threshold.lower ?? Number.MAX_VALUE, result.threshold.upper ?? -Number.MAX_VALUE]
  );
};

/**
 * Add padding to the given range for plotting purposes
 * @param range A range as a tuple of start and end
 * @returns Padded range
 */
export const padRange = (range: [number, number]): [number, number] => {
  const padding = (range[1] - range[0]) * 0.05;
  return [range[0] - padding, range[1] + padding];
};

/**
 * Get the start timestamp for the given data point and plot type
 *
 * The plot type is currently not used, but accepted for symmetry with getStartTimestamp.
 *
 * @param dp Data point to get the start timestamp for
 * @param plotType Plot type to be used
 * @returns The start timestamp of the datapoint
 */
export const getStartTimestamp = (dp: { startTimestamp: DateLike }, plotType: PlotType): number => {
  return new Date(dp.startTimestamp).getTime();
};

/**
 * Get the end timestamp for the given data point and plot type
 *
 * Depending on the plot type the 'end' is taken from the start or end timestamp of the chunk.
 *
 * @param dp Data point to get the end timestamp for
 * @param plotType Plot type to be used
 * @returns The end timestamp of the datapoint
 */
export const getEndTimestamp = (
  dp: { startTimestamp: DateLike; endTimestamp: DateLike },
  plotType: PlotType
): number => {
  if (plotType === PlotType.Line) {
    return new Date(dp.startTimestamp).getTime();
  } else {
    return new Date(dp.endTimestamp).getTime() + 1;
  }
};

/**
 * Get the center timestamp for the given data point
 * @param dp Data point to get the center timestamp for
 * @returns The center timestamp of the datapoint
 */
export const getCenterTimestamp = (dp: { startTimestamp: DateLike; endTimestamp: DateLike }): number => {
  return getStartDate(dp).getTime() + getDateStep(dp) / 2;
};

/**
 * Get the start date for the given data point
 * @param dp Data point to get the start date for
 * @returns The start date of the datapoint
 */
export const getStartDate = (dp: { startTimestamp: DateLike }): Date => {
  return new Date(dp.startTimestamp);
};

/**
 * Get the end date for the given data point
 * @param dp Data point to get the end date for
 * @returns The end date of the datapoint
 */
export const getEndDate = (dp: { endTimestamp: DateLike }): Date => {
  return new Date(new Date(dp.endTimestamp).getTime() + 1);
};
