import { useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";
import { Loader2 } from "lucide-react";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { AnalysisType, ResultRefInput, SummarySectionResultFragment, gql } from "@/apis/nannyml";
import { alert } from "@/components/Dialog";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { PlotConfigContextProvider } from "@/components/monitoring/PlotConfig";
import { ResultView, supportsConceptDrift } from "@/domains/monitoring";
import { SUMMARY_TAG_NAME } from "@/domains/tags/tagsOptions";
import { resultViewLabels } from "@/formatters/monitoring";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useTagResult } from "@/hooks/useTagResult";
import { selectWhere } from "@/lib/typesUtils";
import { useParamsModelId } from "@/routes/useParamsModelId";

import {
  EditSummaryConceptDrift,
  EditSummaryCovariateShift,
  EditSummaryDataQuality,
  EditSummaryPerformance,
} from "./EditSummary";
import { LatestRunStatus } from "./LatestRunStatus";
import { SummarySection } from "./SummarySection";

export const getSummaryQuery = gql(/* GraphQL */ `
  query GetSummary($modelId: Int!) {
    monitoring_model(id: $modelId) {
      id
      problemType
      hasInitialRunCompleted

      latestRun {
        ...MonitoringRunStatus
      }

      kpm {
        metric
        component
        results(segments: [null]) {
          __typename
          id
          analysisType
          metricName
          ...SummarySectionResult
        }
      }

      summaryResults: results(filter: { tags: ["summary"] }) {
        __typename
        id
        analysisType
        ... on TimeSeriesResult {
          metricName
        }
        ...SummarySectionResult
      }

      results(filter: { segments: [null] }) {
        ...SummaryResult
      }
    }
  }
`);

type ModelType = NonNullable<ResultOf<typeof getSummaryQuery>["monitoring_model"]>;
type ResultType = Extract<ModelType["summaryResults"][number], { __typename: "TimeSeriesResult" }>;

const sections: Record<
  ResultView,
  {
    isSupported?: (model: ModelType) => boolean;
    resultFilter: (results: ResultType) => boolean;
    EditSectionComponent: typeof EditSummaryPerformance;
    flags: Record<string, (model: ModelType) => (result: SummarySectionResultFragment) => boolean>;
  }
> = {
  [ResultView.Performance]: {
    resultFilter: (r) =>
      r.analysisType === AnalysisType.RealizedPerformance || r.analysisType === AnalysisType.EstimatedPerformance,
    EditSectionComponent: EditSummaryPerformance,
    flags: {
      kpm: (model) => (result) => model.kpm.results.some((r) => r.id === result.id),
    },
  },
  [ResultView.ConceptDrift]: {
    isSupported: (model) => supportsConceptDrift(model.problemType),
    resultFilter: (r) => r.analysisType === AnalysisType.ConceptShift,
    EditSectionComponent: EditSummaryConceptDrift,
    flags: {},
  },
  [ResultView.CovariateShift]: {
    resultFilter: (r) => r.analysisType === AnalysisType.FeatureDrift || r.analysisType === AnalysisType.SummaryStats,
    EditSectionComponent: EditSummaryCovariateShift,
    flags: {},
  },
  [ResultView.DataQuality]: {
    resultFilter: (r) => r.analysisType === AnalysisType.DataQuality,
    EditSectionComponent: EditSummaryDataQuality,
    flags: {},
  },
};

export const ModelSummary = () => {
  const modelId = useParamsModelId();
  const { switchTag, removeTag } = useTagResult();
  const [selectedPlots, setSelectedPlots] = useLocalStorage<string[]>(`summaryPlots-${modelId}`, []);

  const { data, error, loading } = useQuery(getSummaryQuery, {
    variables: { modelId: modelId! },
    skip: !modelId && modelId !== 0,
  });

  if (loading || error) {
    return (
      <RequestStateLayout
        isLoading={loading}
        hasError={Boolean(error)}
        errorContent={<div>Error loading model summary</div>}
      />
    );
  }

  const model = data?.monitoring_model;
  if (!model) {
    throw new Error(`Model ${modelId} not found`);
  }

  const kpmResults = model.kpm?.results ?? [];
  const summaryResults = model.summaryResults.filter(selectWhere("__typename", "TimeSeriesResult")) ?? [];
  const results = _.uniqBy(kpmResults.concat(_.sortBy(summaryResults, (r) => r.metricName)), (r) => r.id);
  const allResults = model.results?.filter(selectWhere("__typename", "TimeSeriesResult")) ?? [];

  const onResultRemove = (resultRef: ResultRefInput, resultId: string) => {
    if (kpmResults.some((r) => r.id === resultId)) {
      alert({
        title: "Cannot remove KPM",
        message: (
          <div className="flex flex-col gap-4">
            <span>Key performance metrics cannot be removed from the summary page.</span>
            <span>
              You can select a different metric as KPM on the{" "}
              {/* FIXME: Replace this with a Link component. Link currently doesn't work because the alert is rendered
                  in a separate react DOM, causing the Link to component to fail */}
              <a className="underline" href={`/monitoring/model/${modelId}/settings/performance`}>
                model settings
              </a>{" "}
              page.
            </span>
          </div>
        ),
        variant: "error",
      });
    }
    removeTag(SUMMARY_TAG_NAME, resultRef);
    onPlotOpenChange(resultId, false);
  };

  const onPlotOpenChange = (resultId: string, open: boolean) => {
    setSelectedPlots((selectedPlots) =>
      open ? selectedPlots.concat(resultId) : selectedPlots.filter((p) => p !== resultId)
    );
  };

  const onResultSelect = (resultRef: ResultRefInput, resultId: string, selected: boolean) => {
    switchTag(selected, SUMMARY_TAG_NAME, resultRef);
    onPlotOpenChange(resultId, selected);
  };

  return (
    <div className={"flex flex-col h-full gap-4 p-4"}>
      <div className={"fcol gap-4"}>
        <Text size={"large"} stroke={"boldLight"}>
          Summary
        </Text>
        <LatestRunStatus run={data?.monitoring_model?.latestRun ?? null} />
      </div>

      {data?.monitoring_model?.hasInitialRunCompleted ? (
        <div className="flex flex-col">
          {Object.entries(sections).map(([section, config]) => {
            if (config.isSupported && !config.isSupported(data!.monitoring_model!)) {
              return null;
            }

            const sectionResults = results.filter(config.resultFilter);
            return (
              <PlotConfigContextProvider key={section} storeName={section}>
                <SummarySection
                  title={resultViewLabels[section as ResultView]}
                  summaryResults={sectionResults}
                  plotResults={sectionResults.filter((r) => selectedPlots.includes(r.id))}
                  onPlotOpenChange={onPlotOpenChange}
                  onResultRemove={onResultRemove}
                  detailsLink={`/monitoring/model/${modelId}/${section}`}
                  editSectionComponent={
                    <config.EditSectionComponent
                      modelId={modelId}
                      results={allResults.filter(config.resultFilter)}
                      onResultSelect={onResultSelect}
                    />
                  }
                  flags={Object.fromEntries(Object.entries(config.flags).map(([key, flagFn]) => [key, flagFn(model)]))}
                />
              </PlotConfigContextProvider>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col flex-auto gap-8 justify-center items-center">
          <Text size="medium2">Please hold on while we crunch the numbers...</Text>
          <Loader2 size={64} className="animate-spin text-highlightDeep" />
          <Text>This could take a while depending on the size of your datasets.</Text>
        </div>
      )}
    </div>
  );
};
