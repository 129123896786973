import { createContext, useContext } from "react";
import { StoreApi, useStore } from "zustand";

import { DataPeriod as PlotDataset } from "@/apis/nannyml";
import { PlotElements, PlotType } from "@/constants/enums";

export type PlotConfig = {
  type: PlotType;
  elements: PlotElements[];
  datasets: PlotDataset[];
  subplotPerDataset: boolean;
};

export type PlotConfigStore = PlotConfig & {
  setPlotConfig: (config: Partial<PlotConfig>) => void;
};

export const PlotConfigContext = createContext<null | StoreApi<PlotConfigStore>>(null);

type UsePlotConfigType = {
  (): PlotConfigStore;
  <T>(selector: (state: PlotConfigStore) => T): T;
};

export const usePlotConfig: UsePlotConfigType = <T>(selector?: (state: PlotConfigStore) => T) => {
  const ctx = useContext(PlotConfigContext);

  if (!ctx) {
    throw new Error("usePlotConfig must be used within a PlotConfigContextProvider");
  }

  if (!selector) {
    selector = (state) => state as T;
  }

  return useStore(ctx, selector);
};
