import { PropsWithChildren, useId, useRef } from "react";
import { StoreApi, StoreMutators, createStore } from "zustand";
import { persist } from "zustand/middleware";

import { DataPeriod as PlotDataset } from "@/apis/nannyml";
import { RadioGroup, RadioGroupLabeledItem } from "@/components/RadioGroup";
import { Checkbox } from "@/components/common/Checkbox/Checkbox";
import { SelectCheckboxList } from "@/components/dashboard/SelectCheckbox/SelectCheckboxList";
import { PlotElements, PlotType } from "@/constants/enums";
import { dataPeriodLabels } from "@/formatters/monitoring";
import { plotElementLabels, plotTypeLabels, subplotPerDatasetLabel } from "@/formatters/plots";

import { PlotConfigContext, PlotConfigStore, usePlotConfig } from "./PlotConfig.context";

export const PlotConfigContextProvider = ({
  children,
  storeName,
}: PropsWithChildren<{
  storeName: string;
}>) => {
  const storeRef = useRef<StoreMutators<StoreApi<PlotConfigStore>, PlotConfigStore>["zustand/persist"]>();

  // Create store if it doesn't exist or if the store name has changed. This will read the persisted state from local
  // storage if it exists
  if (!storeRef.current || storeRef.current.persist.getOptions().name !== `PlotConfigStore.${storeName}`) {
    storeRef.current = createStore(
      persist<PlotConfigStore>(
        (set) => ({
          type: PlotType.Line,
          elements: Object.values(PlotElements),
          datasets: [PlotDataset.Reference, PlotDataset.Analysis],
          subplotPerDataset: false,
          setPlotConfig: set,
        }),
        {
          name: `PlotConfigStore.${storeName}`,
          merge: (persisted, initial) => {
            // Sort datasets to ensure that reference data is always displayed before analysis data
            // Normally not required, but clients with old data may still have unsorted datasets
            const { datasets, ...rest } = persisted as { datasets: PlotDataset[] };
            return { ...initial, ...rest, datasets: datasets.toSorted().reverse() };
          },
        }
      )
    );
  }

  return <PlotConfigContext.Provider value={storeRef.current}>{children}</PlotConfigContext.Provider>;
};

export const PlotDatasetsConfig = () => {
  const id = useId();
  const { subplotPerDataset, datasets, setPlotConfig } = usePlotConfig();

  const setSelectedDatasets = (updateDatasets: (currentDatasets: string[]) => string[]) => {
    // Sort datasets to ensure that reference data is always displayed before analysis data
    const newDatasets = updateDatasets(datasets).toSorted().reverse() as PlotDataset[];
    setPlotConfig({ datasets: newDatasets });
  };

  return (
    <div className="flex flex-col gap-4">
      <SelectCheckboxList
        values={Object.values(PlotDataset)}
        selectedValues={datasets}
        setSelectedValues={setSelectedDatasets}
        showSearch={false}
        names={dataPeriodLabels}
      />
      <div className="flex gap-2 items-center">
        <Checkbox
          id={id}
          checked={subplotPerDataset}
          onCheckedChange={(checked) => setPlotConfig({ subplotPerDataset: Boolean(checked) })}
        />
        <label htmlFor={id}>{subplotPerDatasetLabel}</label>
      </div>
    </div>
  );
};

export const PlotTypeConfig = ({ plotTypes = Object.values(PlotType) }: { plotTypes?: PlotType[] }) => {
  const { type, setPlotConfig } = usePlotConfig();

  return (
    <RadioGroup value={type} onValueChange={(type) => setPlotConfig({ type: type as PlotType })}>
      {plotTypes.map((value) => (
        <RadioGroupLabeledItem key={value} value={value} label={plotTypeLabels[value]} />
      ))}
    </RadioGroup>
  );
};

export const PlotElementsConfig = () => {
  const { elements, setPlotConfig } = usePlotConfig();

  const setSelectedElements = (updateElements: (currentElements: string[]) => string[]) => {
    setPlotConfig({ elements: updateElements(elements) as PlotElements[] });
  };

  return (
    <SelectCheckboxList
      values={Object.values(PlotElements)}
      selectedValues={elements}
      setSelectedValues={setSelectedElements}
      showSearch={false}
      names={plotElementLabels}
    />
  );
};
