import { useState } from "react";

import { Divider } from "@/components/Divider";
import {
  DescriptiveStatisticsEnableConfig,
  DescriptiveStatisticsThresholdConfig,
} from "@/components/monitoring/RuntimeConfig";
import { thresholdDesc } from "@/formatters/thresholds";

import { EditModelControls, Subtitle, Title, useModelEditor } from "./EditModel";
import { SegmentSelect } from "./SegmentSelect";

export const DescriptiveStatisticsSettings = () => {
  const { model, config, onRuntimeConfigChange } = useModelEditor();
  const [segmentId, setSegmentId] = useState<number>();

  return (
    <div className="p-4 flex flex-col w-fit max-w-[870px] gap-4">
      <Title>Descriptive statistics configuration</Title>
      <Subtitle>
        Configure which descriptive statistics to analyse. These are lightweight statistics that provide insight into
        the data distribution.
      </Subtitle>
      <DescriptiveStatisticsEnableConfig
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
      />
      <Title>Descriptive statistics thresholds</Title>
      <Subtitle>{thresholdDesc}</Subtitle>
      <SegmentSelect segments={model.segments} onSegmentChange={(segment) => setSegmentId(segment?.id)} />
      <DescriptiveStatisticsThresholdConfig
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
        segmentId={segmentId}
      />
      <EditModelControls className="sticky bottom-0 py-4 -my-4 bg-dark" />
    </div>
  );
};
