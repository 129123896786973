import React from "react";

import { cn } from "@/lib/utils";

export const ButtonGroup = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "flex flex-row gap-0 [&>*]:py-3 [&>*]:px-5",
        "[&>*]:border [&>:not(:last-of-type)]:border-r-0 [&>*]:border-gray-500",
        "[&>:first-of-type]:rounded-l-lg [&>:last-of-type]:rounded-r-lg",
        "[&>:hover]:bg-slate-700/50 [&>[aria-checked='true']]:bg-dark/80",
        className
      )}
      {...props}
    />
  )
);
