import {
  AlignLeftIcon,
  BarChart4Icon,
  FunctionSquareIcon,
  SettingsIcon,
  SheetIcon,
  FileCheckIcon,
  FileTextIcon,
  TrendingUpIcon,
} from "lucide-react";
import { RouteObject, Outlet, Navigate } from "react-router-dom";

import { Divider } from "@/components/Divider";
import { Navigation, NavigationContent, NavigationRoutes, useNavigationOpen } from "@/components/Navigation";
import { ModelContextProvider, useModelContext } from "@/components/monitoring";
import { ResultView } from "@/domains/monitoring";
import { problemTypeLabels } from "@/formatters/models";
import { resultViewLabels } from "@/formatters/monitoring";
import { cn } from "@/lib/utils";
import { useParamsModelId } from "@/routes/useParamsModelId";

import { ModelDataSettings } from "../Data";
import { ModelResultRoute } from "../ModelResultView";
import { ModelRunOverview } from "../ModelRunOverview";
import { ModelSettings } from "../ModelSettings/ModelSettings";
import { ModelSummary } from "../Summary";

const routes: RouteObject[] = [
  {
    path: "",
    element: <Navigate to="summary" replace />,
  },
  {
    path: "summary",
    element: <ModelSummary />,
    handle: {
      breadcrumb: "Summary",
      icon: SheetIcon,
      group: "Monitoring",
    },
  },
  {
    path: ResultView.Performance,
    element: <ModelResultRoute view={ResultView.Performance} />,
    handle: {
      breadcrumb: resultViewLabels[ResultView.Performance],
      icon: TrendingUpIcon,
      group: "Monitoring",
    },
  },
  {
    path: ResultView.CovariateShift,
    element: <ModelResultRoute view={ResultView.CovariateShift} />,
    handle: {
      breadcrumb: resultViewLabels[ResultView.CovariateShift],
      icon: BarChart4Icon,
      group: "Monitoring",
    },
  },
  {
    path: ResultView.ConceptDrift,
    element: <ModelResultRoute view={ResultView.ConceptDrift} />,
    handle: {
      breadcrumb: resultViewLabels[ResultView.ConceptDrift],
      icon: FunctionSquareIcon,
      group: "Monitoring",
    },
  },
  {
    path: ResultView.DataQuality,
    element: <ModelResultRoute view={ResultView.DataQuality} />,
    handle: {
      breadcrumb: resultViewLabels[ResultView.DataQuality],
      icon: FileCheckIcon,
      group: "Monitoring",
    },
  },
  {
    path: "data",
    element: <ModelDataSettings />,
    handle: {
      breadcrumb: "Data",
      icon: FileTextIcon,
      group: "Management",
    },
  },
  {
    path: "runs",
    element: <ModelRunOverview />,
    handle: {
      breadcrumb: "Logs",
      icon: AlignLeftIcon,
      group: "Management",
    },
  },
  {
    path: "settings",
    element: <ModelSettings />,
    handle: {
      breadcrumb: "Settings",
      icon: SettingsIcon,
      group: "Management",
    },
    children: ModelSettings.routes,
  },
];

export const ModelContainer = () => {
  const modelId = useParamsModelId();

  return (
    <ModelContextProvider modelId={modelId}>
      <div className="flex flex-grow bg-dark max-h-full max-w-full">
        <Navigation id="monitoring.ModelContainer" className="min-w-fit">
          <Header />
          <Divider orientation="horizontal" className="mt-1 mb-0" />
          <NavigationRoutes routes={routes} url={`/monitoring/model/${modelId}`} />
        </Navigation>
        <Divider orientation="vertical" margin="none" />
        <div className="flex-grow overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </ModelContextProvider>
  );
};
ModelContainer.routes = routes;

const Header = () => {
  const { id, name, problemType } = useModelContext();
  const [isOpen] = useNavigationOpen();

  return isOpen ? (
    <NavigationContent className="h-[60px] px-4 grid grid-cols-[auto_1fr] items-center gap-x-2 py-2">
      <span className="truncate max-w-[min(20vw,25ch)]" title={name}>
        {name}
      </span>
      <span className="text-sm text-gray-400">#{id}</span>
      <span className="col-span-full text-sm text-gray-400">{problemTypeLabels[problemType]}</span>
    </NavigationContent>
  ) : (
    <span className={cn("h-[60px] mx-auto items-end text-sm text-gray-400 flex py-2")}>#{id}</span>
  );
};
