import { ResultOf } from "@graphql-typed-document-node/core";
import { Loader2Icon } from "lucide-react";
import React from "react";
import { useResizeDetector } from "react-resize-detector";

import { padRange } from "@/adapters/monitoring";
import { FragmentType, gql, useFragment } from "@/apis/nannyml";
import { AddTagButton, RemovableResultTag } from "@/components/Tag";
import { usePlotConfig } from "@/components/monitoring/PlotConfig";
import { PlotType } from "@/constants/enums";
import { getResultTitles } from "@/formatters/monitoring";
import { DateLike } from "@/lib/dateUtils";
import { cn } from "@/lib/utils";

import { useDateFilterContext } from "../ResultFilters";
import { DistributionResultPlot } from "./Distribution";
import { PlotPlaceholder } from "./PlotPlaceholder";
import { TimeSeriesResultPlot } from "./TimeSeries";

const resultPlotFragment = gql(/* GraphQL */ `
  fragment ResultPlot on TimeSeriesResult {
    __typename
    id
    modelId
    analysisType
    calculatorType
    metricName
    componentName
    columnName
    segment {
      id
      segmentColumnName
      segment
    }
    tags
  }
`);

type ResultPlotFragment = ResultOf<typeof resultPlotFragment>;

export const ResultPlotTitle = ({
  className,
  result,
  showTags,
}: {
  className?: string;
  result: ResultPlotFragment;
  showTags?: boolean;
}) => {
  const titles = getResultTitles(result);

  return (
    <div className={cn("inline-grid grid-cols-[repeat(3,auto)] grid-rows-2 gap-x-2 items-center text-pale", className)}>
      <span className="text-gray-400 col-span-2">
        {result.segment ? `${result.segment.segmentColumnName}: ${result.segment.segment}` : "All data"}
      </span>
      <span className="font-bold">{titles.title}</span>
      <span>{titles.subtitle}</span>
      <div className="row-span-full col-start-3 flex flex-wrap gap-2">
        {showTags && (
          <>
            {result.tags.map((tag) => (
              <RemovableResultTag key={tag} tag={tag} result={result} />
            ))}
            <AddTagButton result={result} />
          </>
        )}
      </div>
    </div>
  );
};

export const ResultPlot = React.memo(
  ({
    dateRange,
    className,
    results: resultFragments,
    renderTitle = ResultPlotTitle,
  }: {
    dateRange?: [DateLike, DateLike];
    className?: string;
    results: FragmentType<typeof resultPlotFragment>[];
    renderTitle?: (props: { result: ResultPlotFragment }) => React.ReactNode;
  }) => {
    const results = useFragment(resultPlotFragment, resultFragments) as ResultPlotFragment[];
    const plotConfig = usePlotConfig();
    const { width, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    const [key, setKey] = React.useState(0);

    // Reset key when plot config changes to force error boundary to re-render
    React.useEffect(() => {
      setKey((key) => key + 1);
    }, [plotConfig]);

    return (
      <div ref={ref} className={cn("flex flex-col", className)}>
        <div className="flex gap-2 items-center">
          {results.map((result, idx) => (
            <React.Fragment key={idx}>
              {idx > 0 && <span className="text-pale">vs.</span>}
              {renderTitle({ result })}
            </React.Fragment>
          ))}
        </div>
        <React.Suspense
          fallback={
            <PlotPlaceholder>
              <Loader2Icon className="animate-spin text-highlightDeep mr-2" size={20} />
              <p>Loading data</p>
            </PlotPlaceholder>
          }
        >
          <PlotError key={key}>
            {plotConfig.type === PlotType.Distribution ? (
              <DistributionResultPlot dateRange={dateRange} results={results} width={width} />
            ) : (
              <TimeSeriesResultPlot dateRange={dateRange} results={results} width={width} />
            )}
          </PlotError>
        </React.Suspense>
      </div>
    );
  }
);
ResultPlot.displayName = "ResultPlot";

export const ActiveDateRangeResultPlot = (props: {
  className?: string;
  results: FragmentType<typeof resultPlotFragment>[];
  renderTitle?: (props: { result: ResultPlotFragment }) => React.ReactNode;
}) => {
  const { activeDateRange } = useDateFilterContext();

  const dateRange = React.useMemo(() => padRange(activeDateRange), [activeDateRange]);

  return (
    <ResultPlot
      results={props.results}
      dateRange={dateRange}
      className={props.className}
      renderTitle={props.renderTitle ?? ResultPlotTitle}
    />
  );
};

type PlotErrorProps = {
  children?: React.ReactNode;
};

type PlotErrorState = {
  error?: string;
};

class PlotError extends React.Component<PlotErrorProps, PlotErrorState> {
  static getDerivedStateFromError(error: any) {
    return { error: error.message };
  }

  render() {
    if (!this.state?.error) {
      return this.props.children;
    }

    return (
      <PlotPlaceholder>
        <p>{this.state.error}</p>
      </PlotPlaceholder>
    );
  }
}
